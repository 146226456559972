<template>
  <v-container
    style="margin: auto"
    class="blog fill-height justify-center align-center"
  >
    <h1 style="margin-bottom: 3%; margin-top: 3%">
      How to Cite YouTube Videos in MLA Format
    </h1>

    <v-row>
      <p>
        To use the information you find with Pop Mystic’s movie line search
        engine, you will need to cite it, and this article is intended as a
        quick reference for citing the movies and television shows you want to
        quote or analyze in the Modern Language Association (MLA) format. This
        format guide is specifically for direct quotations from movies, tv, and
        video. Much of the information presented here is a synopsis of the
        in-depth and detailed information available at
        <a href="https://www.scribbr.com/mla/youtube-citation/">Scribbr.com</a>
      </p>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" lg="2" xl="2" align="center" justify="center">
        <v-img
          class="mx-auto"
          src="../../assets/mla/mla-logo.jpg"
          contain
          max-width="150px"
        ></v-img> </v-col
      ><v-col cols="12" md="10" lg="10" xl="10">
        MLA is the format most commonly used in the Humanities. Research papers
        in disciplines like history, literature, philosophy, and visual arts are
        most commonly formatted in MLA style. Disciplines like
        <a href="https://en.wikipedia.org/wiki/Film_studies">Film</a> and
        <a href="https://en.wikipedia.org/wiki/Media_studies">Media Studies</a>
        fall in this category and are the most likely to need to cite non-text
        sources, so the formatting information presented here is for MLA format.
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>Bibliography Citation</h2>
    </v-row>
    <v-row style="margin-bottom: 20px" align="center" justify="center">
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-img
          class="mx-auto"
          src="../../assets/mla/antenna-man.jpg"
          contain
          max-height="300px"
        ></v-img>
      </v-col>
      <v-col cols="8" md="8" lg="8" xl="8">
        Format
        <v-card color="primary">
          <v-card-text>
            Author’s last name, First name. “Title of Video.” Website, uploaded
            by Uploader, Day Month Year of publication, URL.
          </v-card-text>
        </v-card>
        <br />
        Format when the author is the uploader
        <v-card color="primary">
          <v-card-text>
            “Title of Video.” Website, uploaded by Uploader, Day Month Year of
            publication, URL.
          </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text>
            “Five TV Antennas to Avoid Buying When Cutting the Cord”. YouTube,
            uploaded by Antenna Man, 23 Mar. 2021,
            https://www.youtube.com/watch?v=7qQaB8tjc_I&t=282s.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>In-Text Citation</h2>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="10" md="8" lg="8" xl="8">
        Format
        <v-card color="primary">
          <v-card-text>(“Video Title” 00:00-00:00)</v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text
            >Among antenna experts, it is commonly held that omnidirectional
            antennas are inferior to directional antennas when dealing with low
            signal strength (“Five TV Antennas” 2:33-56).</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MlaYoutube",
  data: () => ({
    paragraphs: [
      {
        text: "Just as you cite a book or magazine in your research papers, you have to cite the movies that have contributed to your position. To use the TV and movie quotes you’ll find on <Pop Mystic with link> in any kind of real research, you will need to cite its source. This article is a reference for APA style (7th Edition) citations for the quotes from movies and television shows you want to use to support your arguments. Much of the information presented here is a quick synopsis of the excellent and detailed information available at Scribbr.com <https://www.scribbr.com/category/apa-style/>",
        imageSrc: "src/assets/amazon-button1.png",
      },
    ],
  }),

  components: {},
};
</script>

<style scoped>
.blog {
  font-weight: 600;
  color: black;
}
h2 {
  text-decoration: underline;
  text-align: center;
}
h1 {
  text-align: center;
}
p {
  text-align: center;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: black;
}
.container {
  max-width: 900px;
}
@media (min-width: 1904px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width: 1264px) {
  .container {
    max-width: 900px;
  }
}
</style>
